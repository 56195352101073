
import './App.css';
import * as React from 'react';
import { ScheduleComponent, Day, Month, TimelineViews,TimelineMonth, Inject, ExcelExport, Resize, DragAndDrop,ViewsDirective, ViewDirective, ResourcesDirective, ResourceDirective } from '@syncfusion/ej2-react-schedule';
import { DataManager,  UrlAdaptor } from '@syncfusion/ej2-data';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns'
//import { MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars'
import { L10n,loadCldr } from '@syncfusion/ej2-base';
//import * as localeTexts from './locale.json';
import { useState} from 'react';
import {BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';
import Login from './login.js';
import { useRef } from 'react';

import "../node_modules/@syncfusion/ej2-base/styles/material.css";
import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
import "../node_modules/@syncfusion/ej2-calendars/styles/material.css";
import "../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
import "../node_modules/@syncfusion/ej2-schedule/styles/material.css";
import "../node_modules/@syncfusion/ej2-icons/styles/bootstrap5.css";
import "../node_modules/@syncfusion/ej2-icons/styles/material.css";

loadCldr(
  require('cldr-data/supplemental/numberingSystems.json'),
  require('cldr-data/main/de/ca-gregorian.json'),
  require('cldr-data/main/de/numbers.json'),
  require('cldr-data/main/de/timeZoneNames.json'),
  require('cldr-data/supplemental/weekData.json')
   );
   L10n.load({
    "de": {
        "schedule": {
            "day": "Tag",
            "week": "Woche",
            "workWeek": "Arbeitswoche",
            "month": "Monat",
            "agenda": "Agenda",
            "weekAgenda": "Wochenagenda",
            "workWeekAgenda": "Arbeitswochenagenda",
            "monthAgenda": "Monatsagenda",
            "today": "Heute",
            "noEvents": "keine Veranstaltungen",
            "emptyContainer": "There are no events scheduled on this day.",
            "allDay": "All day",
            "start": "Start",
            "end": "Ende",
            "more": "mehr",
            "close": "Schließen",
            "cancel": "Abbrechen",
            "noTitle": "(Kein Titel)",
            "delete": "Löschen",
            "deleteEvent": "Lösche Veranstaltung",
            "deleteMultipleEvent": "Delete Multiple Events",
            "selectedItems": "Items selected",
            "deleteSeries": "Delete Series",
            "edit": "Bearbeiten",
            "editSeries": "Edit Series",
            "editEvent": "Termin bearbeiten",
            "createEvent": "Erstellen",
            "subject": "Titel",
            "addTitle": "Mitteilung",
            "moreDetails": "Mehr Details",
            "save": "Speichern",
            "editContent": "Do you want to edit only this event or entire series?",
            "deleteRecurrenceContent": "Do you want to delete only this event or entire series?",
            "deleteContent": "Sind Sie sicher, dass Sie diese Eingabe löschen wollen?",
            "deleteMultipleContent": "Are you sure you want to delete the selected events?",
            "newEvent": "Neue Veranstaltung",
            "title": "Titel",
            "location": "Ort",
            "description": "Leitung",
            "timezone": "Zeitzone",
            "startTimezone": "Start Timezone",
            "endTimezone": "End Timezone",
            "repeat": "Repeat",
            "saveButton": "Speichern",
            "cancelButton": "Abbrechen",
            "deleteButton": "Löschen",
            "recurrence": "Recurrence",
            "wrongPattern": "The recurrence pattern is not valid.",
            "seriesChangeAlert": "The changes made to specific instances of this series will be cancelled and those events will match the series again.",
            "createError": "The duration of the event must be shorter than how frequently it occurs. Shorten the duration, or change the recurrence pattern in the recurrence event editor.",
            "recurrenceDateValidation": "Some months have fewer than the selected date. For these months, the occurrence will fall on the last date of the month.",
            "sameDayAlert": "Two occurrences of the same event cannot occur on the same day.",
            "editRecurrence": "Edit Recurrence",
            "repeats": "Repeats",
            "alert": "Alert",
            "startEndError": "The selected end date occurs before the start date.",
            "invalidDateError": "The entered date value is invalid.",
            "ok": "Ok",
            "occurrence": "Occurrence",
            "series": "Series",
            "previous": "Previous",
            "next": "Next",
            "timelineDay": "Timeline Day",
            "timelineWeek": "Timeline Week",
            "timelineWorkWeek": "Timeline Work Week",
            "timelineMonth": "Zeitachse-Monat",
            "expandAllDaySection": "Expand",
            "collapseAllDaySection": "Collapse"
        },
        "recurrenceeditor": {
            "none": "None",
            "daily": "Daily",
            "weekly": "Weekly",
            "monthly": "Monthly",
            "month": "Month",
            "yearly": "Yearly",
            "never": "Never",
            "until": "Until",
            "count": "Count",
            "first": "First",
            "second": "Second",
            "third": "Third",
            "fourth": "Fourth",
            "last": "Last",
            "repeat": "Repeat",
            "repeatEvery": "Repeat Every",
            "on": "Repeat On",
            "end": "End",
            "onDay": "Day",
            "days": "Day(s)",
            "weeks": "Week(s)",
            "months": "Month(s)",
            "years": "Year(s)",
            "every": "every",
            "summaryTimes": "time(s)",
            "summaryOn": "on",
            "summaryUntil": "until",
            "summaryRepeat": "Repeats",
            "summaryDay": "day(s)",
            "summaryWeek": "week(s)",
            "summaryMonth": "month(s)",
            "summaryYear": "year(s)"
        }
    }
});
   
function App() {

  const [user, setUser] = useState(null);
  const handleLogin = (username) => {
    setUser(username);
  };
   
  return (
    <Router>
      <div>
      <Routes>
        <Route exact path = "/" 
         element = {user ? <Navigate to = "/calendar" /> : <Login onLogin={handleLogin}/>} />
        <Route path="/calendar"
        element = {user ? <Calendar/> : <Navigate to = "/"/>}/>
        
        </Routes>
      </div>
    </Router>
  )};


  function Calendar(){
  
  const scheduleObj = useRef(null);
  const onActionBegin = (args) => {
    if (args.requestType === 'toolbarItemRendering'){
      let exportItem = {
        align: 'Right', 
        showTextOn: 'Both',
        preficIcon: 'e-icon-schedule-excel-export',
        text:'Excel Export',
        cssClass: 'e-excel-export',
        click: onExportClick
      };
      args.items.push(exportItem);
    }
  }
  const onExportClick = () => {
    let exportValues = {
      fields: ['id', 'starttime', 'endtime', 'subject', 'location', 'description', 'OwnerId', 'notice']
    };
    scheduleObj.current.exportToExcel(exportValues);
  }
  const hostIp = window.location.hostname;
  const dataManagerUrl = `http://${hostIp}:8080/api/scheduleevents/getData`;
  const dataManagerCrudUrl = `http://${hostIp}:8080/api/scheduleevents/crudActions`;
  const dataManager = new DataManager({
    url: dataManagerUrl,
    //'http://192.168.178.51:8080/api/scheduleevents/getData',  
    //'http://localhost:8080/api/scheduleevents/getData',
    crudUrl: dataManagerCrudUrl,
     //'http://localhost:8080/api/scheduleevents/crudActions',
    //'http://192.168.178.51:8080/api/scheduleevents/crudActions', 
    adaptor: new UrlAdaptor(),
    crossDomain: true,
   
});



const group ={resources:['Locations']};
const ownerData = [
  { OwnerText: 'Unterkurs', value: 'A', OwnerColor: '#ffaa00' },
  { OwnerText: 'Oberkurs', value: 'B', OwnerColor: '#75be59' },
  { OwnerText: 'Fachleiter', value: 'C', OwnerColor: '#7499e1' },
  { OwnerText: 'Praktikanten', value: 'D', OwnerColor: '#f8a398' },
  {OwnerText: 'Gäste', value: 'E', OwnerColor:'#b42041'},
  {OwnerText: ' ', value: 'F', OwnerColor: '#4d4e6d'},
 
];
const ownerFields = { text: 'OwnerText', value: 'value' };
const locationData = [
  {LocationText: ' ', location: ' ' },
  {LocationText: '2.302', location: '2.302' },
  {LocationText: '2.311', location: '2.311' },
  {LocationText: '2.312', location: '2.312' },
  {LocationText: '2.319', location: '2.319' },
  {LocationText: '2.318', location: '2.318' },
  {LocationText: '2.304', location: '2.304' },
  {LocationText: '2.322', location: '2.322' },
  {LocationText: '2.205', location: '2.205' },  
  {LocationText: 'Sporthalle', location:   'Sporthalle' },
  {LocationText: '2.206', location: '2.206' },
  {LocationText: '2.220', location: '2.220' },
  {LocationText: '2.325', location: '2.325' },
  {LocationText: '2.235', location: '2.235' },
  {LocationText: '2.227', location: '2.227' },
  {LocationText: 'Aula', location:   'Aula' },
  {LocationText: 'Hörsaal H1', location:   'Hörsaal H1' },
  {LocationText: 'Hörsaal H2', location:   'Hörsaal H2' },
  {LocationText: 'Hörsaal H3', location:   'Hörsaal H3' },
  {LocationText: 'Hörsaal H4', location:   'Hörsaal H4' },
  {LocationText: 'Regio', location:   'Regio' },
  {LocationText: 'Ausweichraum', location:   'Ausweichraum' }

] 


  

    

   





const locationFields = { text: 'LocationText', value: 'location' };
const onEventRendered =(args) => {
 // console.log(args.data.OwnerId);
if(args.data.OwnerId!==null){

 let ownerDataPos = ownerData.find(key => key.value===args.data.OwnerId);   
  args.element.style.backgroundColor = ownerDataPos.OwnerColor;
}
 else{
  args.data.OwnerId='F';
 }  
}

const onCellClick = (args) => { 
 //scheduleObj.current.eventSettings = {dataSource: dataManager};
 
  scheduleObj.current.openEditor(args, 'Add');

}


const onPopupOpen = (args) => {
  if (args.type === 'QuickInfo' && !args.data.subject){
    
  
  
  
  
    args.cancel =true;
  }

//Einfügen eines Kopie-Buttons in Quickinfo
  if (args.type === 'QuickInfo'){
//Funktion, die die Daten des aktuellen Events übernimmt und mit neuer Id versieht
     const copyEvent = (props) =>{
      const eventData = {...props,id:scheduleObj.current.getEventMaxID()+1};
      //console.log(eventData);
      scheduleObj.current.addEvent(eventData);
      //scheduleObj.current.openEditor(eventData,'Add',true);
    }
// Zugriff auf header-Leiste mit den Icons
const headerElement = args.element.querySelector('.e-header-icon-wrapper');
console.log(headerElement);
// Erstellen des neuen Icon-Buttons
const customIconButton = document.createElement('button');
customIconButton.className = 'e-edit e-btn e-flat e-lib e-round e-small e-icon-btn'; // Styling-Klassen
customIconButton.onclick = () => {copyEvent(args.data)};

// Icon für den Button festlegen (z.B. ein 'Add' Icon)
const iconSpan = document.createElement('span');

iconSpan.className = 'e-btn-icon e-copy e-icons';

iconSpan.style = '::before'; // Verwende ein Syncfusion-Icon
iconSpan.style.color ='white';
iconSpan.title ="Kopieren";
// Icon zum Button hinzufügen
customIconButton.appendChild(iconSpan);
const targetChild =headerElement.querySelector('.e-delete')
// Button zum Header hinzufügen
if (headerElement && targetChild) {
  headerElement.insertBefore(customIconButton,targetChild);
}
}

// *******************

  if (args.type === 'Editor') {
    
    args.duration = 60;
    let statusElement = args.element.querySelector('#location');
    if (statusElement) {
      statusElement.setAttribute('name', 'location');
    }
    let noticeElement = args.element.querySelector('#notice');
    if(noticeElement) {
      noticeElement.setAttribute('name','notice');
    }
    let ownerElement = args.element.querySelector('#OwnerId');
    if(ownerElement) {
      ownerElement.setAttribute('name','OwnerId');
    }

  }
  
}


const dateHeaderTemplate = (props) => {
  return (<div>
    <div>{props.date.toLocaleDateString('de-DE', {weekday: 'short'}) } </div>
    <div>{props.date.getDate()} </div>
  </div>
    
  );
};



    

    const defaultStartTime = (props) => {
        let start=props.starttime;

        // Überprüfen, ob die ausgewählte Zeit 00:00 ist
        if (start && start.getHours() === 0 && start.getMinutes() === 0) {
            // Zeit auf 08:30 setzen
            start.setHours(8);
            start.setMinutes(30);
            return start; // Aktualisiere den state
        } else {
            return start;
        }
    };

    const defaultEndTime = (props) => {
      let start=props.starttime; 
      let end = props.endtime;

      // Überprüfen, ob die ausgewählte Zeit 00:00 ist
      if (end && end.getHours() === 0 && end.getMinutes() === 0) {
          // Zeit auf 08:30 setzen
          end.setDate(end.getDate()-1);
          end.setHours(start.getHours()+1);
          end.setMinutes(start.getMinutes());

          return end; // Aktualisiere den state
      } else {
          return end;
      }
  };

  
















const timeScale = { enable: true, interval: 60, slotCount: 6 };
const editorTemplate = (props) => {
  
  return (props !== undefined ? <table className="custom-event-editor"  style={{ width: '100%', padding: '5' }}><tbody>
    <tr><td className="e-textlabel">Titel</td><td colSpan={4}>
      <input id="subject" className="e-field e-input" type="text" name="subject" style={{ width: '100%' }} />
    </td></tr>
    <tr><td className="e-textlabel">Raum</td><td colSpan={4}>
      <DropDownListComponent 
      id="location" 
      placeholder='Wähle einen Raum' 
      data-name="location" 
      className="e-field" 
      style={{ width: '100%' }} 
      dataSource={locationData} 
      fields={locationFields} 
      value={props.location }></DropDownListComponent>
    </td></tr>
    <tr><td className="e-textlabel">Beginn</td><td colSpan={4}>
      <DateTimePickerComponent  
      id="starttime" 
      data-name="starttime" 
      value={defaultStartTime(props)} 
      //min={new Date(new Date(props.starttime).getFullYear(),new Date(props.starttime).getMonth(),new Date(props.starttime).getDate(),7,30 )} 
      //max={new Date(new Date(props.starttime).getFullYear()+1,new Date(props.starttime).getMonth(),new Date(props.starttime).getDate(),20,30 )} 
      locale="de" 
      format="dd. MMM HH:mm" 
      timeFormat="HH:mm" 
      step="15"  
      className="e-field">
      </DateTimePickerComponent>
    </td></tr>
    <tr><td className="e-textlabel">Ende</td><td colSpan={4}>
      <DateTimePickerComponent  
      id="endtime" 
      data-name="endtime" 
      value={defaultEndTime(props)} 
      //min={new Date(new Date(props.endtime).getFullYear(),new Date(props.endtime).getMonth(),new Date(props.endtime).getDate()-1, 7,30)} 
      //max={new Date(new Date(props.endtime).getFullYear(),new Date(props.endtime).getMonth(),new Date(props.endtime).getDate(),20,30 )}
      locale="de" 
      format="dd. MMM HH:mm" 
      timeFormat="HH:mm" 
      step="15" 
      className="e-field">
        
      </DateTimePickerComponent>
    </td></tr>
    <tr><td className="e-textlabel">Leitung</td><td colSpan={4}>
     <input id="description" className="e-field e-input" type="text" name="description" style={{ width: '100%' }} />
    </td></tr>
    <tr><td className="e-textlabel">Gruppe</td><td colSpan={4}>
        <DropDownListComponent 
        id="OwnerId" 
        className="e-field" 
        placeholder='Wähle eine Gruppe' 
        data-name="OwnerId" 
        dataSource={ownerData} 
        fields={ownerFields} 
        value={props.EventType || 'F'} />
      </td></tr>
    <tr><td className="e-textlabel">Mitteilung</td><td colSpan={4}>
     <input id="notice" className="e-field e-input" type="text" name='notice' style={{ width: '100%' }} />
    </td></tr>
    </tbody></table> : <div></div>);
}

// Test QuickInfo-Template
const getOwnerGroup = (val) =>{
  if(val !== undefined){
    return ownerData.find(({ value }) => value === val).OwnerText;
  }
}


const quickInfoContentTemplate =(props) =>{
 
  return (
    <table className="e-quickinfo-content" style={{ width: '100%', padding: '5', fontSize:'11pt' }}>
<tbody>
  <tr>
    <td style={{  opacity:'0.5',textAlign:'left'  }} className="e-location e-large e-icons">
    </td>
    <td style={{textAlign:'left',height:'3em'   }}>
    {props.location}
    </td>
  </tr>
  <tr>
    <td style={{textAlign:'left', opacity:'0.5'  }} className="e-clock e-large e-icons">
    </td>
    <td style={{textAlign:'left', height:'3em' }}>
    {props.starttime.toLocaleTimeString('de-DE',{hour:'2-digit',minute:'2-digit'})} - {props.endtime.toLocaleTimeString('de-DE',{hour:'2-digit',minute:'2-digit'})}
    </td>
  </tr>
  <tr>
    <td style={{textAlign:'left', opacity:'0.5'  }} className="e-people e-large e-icons">
    </td>
    <td style={{textAlign:'left', height:'3em'  }} >
    { getOwnerGroup(props.OwnerId)
      }
    </td>
  </tr>
  <tr>
    <td style={{textAlign:'left', opacity:'0.5'  }} className="e-user e-large e-icons">
    </td>
    <td style={{textAlign:'left', height:'3em'  }} >
    {props.description}
    </td>
  </tr>
  <tr>
    <td style={{textAlign:'left', opacity:'0.5'  }} className="e-comment-2 e-large e-icons">
    </td>
    <td style={{textAlign:'left',height:'3em'   }}>
    {props.notice}
    </td>
  </tr>
</tbody>
    </table>
  
  );
}






//






  return (
    <div className="App">
    
      <ScheduleComponent 
      ref={scheduleObj}
      cssClass='excel-Export'
      width='100%' 
      height='650px' 
      currentView='TimelineMonth' 
      locale='de' 
      timeScale={timeScale} 
      editorTemplate={editorTemplate.bind(this)}
      eventRendered={onEventRendered.bind(this)} 
      actionBegin={onActionBegin}
      popupOpen={onPopupOpen.bind(this)} 
      quickInfoTemplates={{content:quickInfoContentTemplate.bind(this)}} 
      group={group} 
      showQuickInfo={true} 
      //dataBound={onDataBound}
      allDay={false} 
      cellClick={onCellClick}
      eventSettings={{ dataSource: dataManager,
       fields: {
        id: 'id',
        subject: { name: 'subject' },
        location: { name: 'location' },
        description: { name: 'description' },
        startTime: { name: 'starttime' },
        endTime: { name: 'endtime' },
        OwnerId: {name: 'OwnerId'}


      } }}>
     
      
      <ResourcesDirective>
      <ResourceDirective field='location' title='location' name='Locations' allowMultiple={false} dataSource={locationData} textField='LocationText' idField='location' allowGroupEdit={false} >
      </ResourceDirective>
      
      
      
    </ResourcesDirective>
    < ViewsDirective >
                            <ViewDirective  option='Day'  startHour='07:00' endHour='20:00' />
                            <ViewDirective option='TimelineMonth' dateHeaderTemplate={dateHeaderTemplate} locale='de'/>
                            <ViewDirective option='Month'  />
                        </ViewsDirective>
    
              <Inject services={[Day, Month, TimelineViews, TimelineMonth, Resize, DragAndDrop,ExcelExport]}/>
            </ScheduleComponent>
    </div>
  );
}

export default App;

//<ResourceDirective field='OwnerId' title='Owner' name='Owners' allowMultiple={false} dataSource={ownerData} textField='OwnerText' idField='Id' allowGroupEdit={true} colorField='OwnerColor'>
//</ResourceDirective>